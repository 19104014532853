<template>
  <div class="w-full">
    <div class="flex flex-wrap border border-black p-2 md:p-4">
      <div class="w-full md:w-1/3 p-2 md:p-4">
        <p class="font-bold border-b border-black mb-4">Image</p>
        <p class=""><router-link to="/image/convert">Convert</router-link></p>
        <p class=""><router-link to="/image/convert">Compress</router-link></p>
      </div>
      <div class="w-full md:w-1/3 p-2 md:p-4">
        <p class="font-bold border-b border-black mb-4">Video</p>
        <p class="text-gray-400">Convert</p>
      </div>
      <div class="w-full md:w-1/3 p-2 md:p-4">
        <p class="font-bold border-b border-black mb-4">Audio</p>
        <p class="text-gray-400">Convert</p>
      </div>
      <div class="w-full md:w-1/3 p-2 md:p-4">
        <p class="font-bold border-b border-black mb-4">PDF</p>
        <p class="text-gray-400">Convert</p>
        <p class="text-gray-400">Compress</p>
        <p class="text-gray-400">Join</p>
      </div>
      <div class="w-full md:w-1/3 p-2 md:p-4">
        <p class="font-bold border-b border-black mb-4">Spreadsheets</p>
        <p class="text-gray-400">Convert</p>
      </div>
      <div class="w-full md:w-1/3 p-2 md:p-4">
        <p class="font-bold border-b border-black mb-4">Text</p>
        <p class="text-gray-400">Case convert</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
}
</script>
