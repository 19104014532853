<template>
  <div class="w-full h-14 bg-blue-600 flex flex-wrap content-center mb-4 items-center">
    <div class="w-1/4 text-white text-2xl px-4">
      <span class="flex md:hidden items-center">
        <img alt="Vue logo" src="./assets/fox_sunglasses.png" class="h-6 inline">
        <span class="pl-2"><router-link to="/">cftb</router-link></span>
      </span>
      <span class="hidden md:flex items-center">
        <img alt="Vue logo" src="./assets/fox_sunglasses.png" class="h-6 inline">
        <span class="pl-2"><router-link to="/">coolfoxtoolbox</router-link></span>
      </span>
    </div>
    <div class="w-3/4 md:w-1/2 px-2">
      <input class="bg-blue-500 appearance-none w-full py-2 px-4 text-white leading-tight placeholder-white focus:outline-none" type="text" placeholder="Search">
    </div>
    <div class="w-1/4 hidden md:flex text-white items-center justify-center">
      <div>Image | Convert</div>
    </div>
  </div>
  <div class="px-2 md:px-4">
    <router-view/>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
